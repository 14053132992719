import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../Vedio/Vedio.css";
import vedio from "../Assets/baldwinVedio.mp4";
import popup_img from "../Assets/popup-message.svg";
import mainbanner from "../Assets/mainbanner.png";
import mainbannermobile from "../Assets/mainbannermobile.png";
import mainbanner2 from "../Assets/mainbanner2.png";
import mainbannermobile2 from "../Assets/mainbannermobile2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrl } from "../../Common/data";
import Loader from "../../loader/Loader";
// import popup_arrow from '../Assets/popup-arrow.svg'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
};
const Video = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    className: "",
    howDidYouHear: ""
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Clear error message when user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission
      console.log("Form data:", formData);
      setLoading(true);
      const res = await fetch(`${baseUrl}/contact/mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      if (res.ok) {
        alert("Enquiry Submitted successfully.");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          className: "",
          howDidYouHear: ""
        });
      }
      setLoading(false);
    } else {
      // Form is invalid, set errors state
      setErrors(validationErrors);
    }
    setLoading(false);
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile no is required";
    } else if (!isValidMobile(formData.mobile)) {
      errors.mobile = "Invalid mobile no";
    }
    if (!formData.className) {
      errors.className = "Class selection is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Email validation regex or any other validation logic
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidMobile = (mobile) => {
    // Mobile number validation regex to allow only 10 digits and disallow alphabetic characters and special characters
    return /^[0-9]{10}$/.test(mobile);
  };

  return (
    <div className="App">
      {loading && <Loader />}
      <div className="vedio-container">
        {/* <video
          className="vedio"
          playsInline
          loop
          muted
          // controls
          alt="All the devices"
          src={vedio}
          ref={videoEl}
        /> */}

        <img
          className="popup-img"
          onClick={() => setShow(true)}
          src={popup_img}
          alt="popup"
        />

        <Slider {...settings}>
          <div>
            <img
              style={{ width: "100%" }}
              class="mobile-img desk-block"
              src={mainbanner}
              alt="popup"
            />
            <img
              style={{ width: "100%" }}
              class="mobile-img mob-block"
              src={mainbannermobile}
              alt="popup"
            />
          </div>
          <div>
            <img
              style={{ width: "100%" }}
              class="mobile-img desk-block"
              src={mainbanner2}
              alt="popup"
            />
            <img
              style={{ width: "100%" }}
              class="mobile-img mob-block"
              src={mainbannermobile2}
              alt="popup"
            />
          </div>
        </Slider>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton>
            {/* <img className="pb-0" src={popup_arrow} alt="popup-arrow" /> */}
          </Modal.Header>

          <Modal.Body>
            <h2 className="admission mb-3 p-0">
              ADMISSION OPEN FOR 2024-2025{" "}
            </h2>
            <Form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  placeholder="Name"
                  onChange={handleChange}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
                {errors.name && (
                  <div
                    className="invalid-feedback"
                    style={{ fontSize: "12px", color: "red", fontWeight: 600 }}>
                    {errors.name}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                {errors.email && (
                  <div
                    className="invalid-feedback"
                    style={{ fontSize: "12px", color: "red", fontWeight: 600 }}>
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  placeholder="Mobile no"
                  value={formData.mobile}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                />
                {errors.mobile && (
                  <div
                    className="invalid-feedback"
                    style={{ fontSize: "12px", color: "red", fontWeight: 600 }}>
                    {errors.mobile}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <select
                  id="className"
                  name="className"
                  value={formData.className}
                  onChange={handleChange}
                  className={`form-select ${
                    errors.className ? "is-invalid" : ""
                  }`}>
                  <option value="">Select class</option>
                  <option>Pre KG</option>
                  <option>LKG</option>
                  <option>UKG</option>
                  <option>Class 1</option>
                  <option>Class 2</option>
                  <option>Class 3</option>
                  <option>Class 4</option>
                  <option>Class 5</option>
                  <option>Class 6</option>
                  <option>Class 7</option>
                  <option>Class 8</option>
                </select>
                {errors.className && (
                  <div
                    className="invalid-feedback"
                    style={{ fontSize: "12px", color: "red", fontWeight: 600 }}>
                    {errors.className}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  id="howDidYouHear"
                  name="howDidYouHear"
                  placeholder="How did you hear about us?"
                  value={formData.howDidYouHear}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.howDidYouHear ? "is-invalid" : ""
                  }`}
                />
                {errors.howDidYouHear && (
                  <div
                    className="invalid-feedback"
                    style={{ fontSize: "12px", color: "red", fontWeight: 600 }}>
                    {errors.howDidYouHear}
                  </div>
                )}
              </div>

              <Button
                type="submit"
                disabled={loading}
                className="custom-btn w-100">
                SUBMIT ENQUIRE
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Video;
