import React from 'react'
import Alumnibanner from '../Components/AlumniComponent/Alumnibanner/Alumnibanner'
import Initiative from '../Components/AlumniComponent/Initiative/Initiative'
import MeetAlumni from '../Components/AlumniComponent/MeetAlumni/MeetAlumni'
import ReactGA from "react-ga4";

ReactGA.initialize("G-KE1087VQ84");

ReactGA.send({ hitType: "pageview", 
                page: window.location.pathname, });


const Alumni = () => {
  return (
    <div>
       <Alumnibanner/>
       <Initiative/>
       <MeetAlumni/>
    </div>
  )
}

export default Alumni