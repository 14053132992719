import React,{useEffect} from 'react'
import {useLocation } from 'react-router-dom';
import Eventsbanner from '../Components/EventsComponents/Eventsbanner/Eventsbanner'
import Ceremony from '../Components/EventsComponents/Ceremony/Ceremony'
import Calander from '../Components/EventsComponents/Calendar/Calander'
import EventsAdmission from '../Components/EventsComponents/EventsAdmission/EventsAdmission'
import EventsInformation from '../Components/EventsComponents/EventsInformation/EventsInformation'
import EventsGallery from '../Components/EventsComponents/EventsGallery/EventsGallery'
import EventsOtherSection from '../Components/EventsComponents/EventsOtherSection/EventsOtherSection'
import ReactGA from "react-ga4";

ReactGA.initialize("G-KE1087VQ84");

ReactGA.send({ hitType: "pageview", 
                page: window.location.pathname, });

const Events = () => {
  // const ScrollToTop = () => {
  //   const { pathname, state } = useLocation();
  
  //   useEffect(() => {
  //     setTimeout(() => {
  //       const scrollPosition = window.innerWidth <= 768 ? 1500 : 2100; // Adjust scroll position for mobile view
  //       window.scrollTo(0, scrollPosition);

  //       if (state && state.targetSection) {
  //         const targetSection = document.querySelector(state.targetSection);
  //         if (targetSection) {
  //           targetSection.scrollIntoView({ behavior: 'smooth' });
  //         }
  //       }
  //     }, 3000); // Adding a slight delay to ensure scroll happens after the render
  //   }, [pathname, state]);
  
  //   return null;
  // };

  const ScrollToTop = () => {
    const { pathname, state } = useLocation();
  
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (state && state.targetSection) {
          const targetSection = document.querySelector(state.targetSection);
          if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100); // Adding a slight delay to ensure scroll happens after the render
    }, [pathname, state]);
    return null;
    };

  return (
    <div style={{background:"#FFF"}}>
        <ScrollToTop/>
        <Eventsbanner/> 
        <Ceremony/>  
        <EventsGallery/>
        <Calander/>  
        <EventsAdmission/> 
        <EventsInformation/> 
        <EventsOtherSection/>
        
    </div>
  )
}

export default Events