import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Form.css";
import regester_now_img from "../Assets/regester-now.svg";
import axios from "axios";
import { baseUrl } from "../../Common/data";
import Loader from "../../loader/Loader";
function FormComponent() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    className: "",
    howDidYouHear: "" // Adding state for textarea
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Clear error message when user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission
      console.log("Form data:", formData);
      setLoading(true);
      const res = await fetch(`${baseUrl}/contact/mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      if (res.ok) {
        alert("Enquiry Submitted successfully.");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          className: "",
          howDidYouHear: ""
        });
      }
      setLoading(false);
    } else {
      // Form is invalid, set errors state
      setErrors(validationErrors);
    }
    setLoading(false);
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile no is required";
    } else if (!isValidMobile(formData.mobile)) {
      errors.mobile = "Invalid mobile no";
    }
    if (!formData.className) {
      errors.className = "Class selection is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Email validation regex or any other validation logic
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidMobile = (mobile) => {
    // Mobile number validation regex to allow only 10 digits and disallow alphabetic characters and special characters
    return /^[0-9]{10}$/.test(mobile);
  };

  return (
    <div className="form-container " style={{ marginLeft: "auto" }}>
      {loading && <Loader />}
      <img className="regester_now" src={regester_now_img} alt="regester now" />
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mb-3">
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            placeholder="Name"
            onChange={handleChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Control
            type="email"
            name="email"
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="mobile" className="mb-3">
          <Form.Control
            type="text"
            name="mobile"
            placeholder="Mobile no"
            value={formData.mobile}
            onChange={handleChange}
            isInvalid={!!errors.mobile}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mobile}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="className" className="mb-3">
          <Form.Control
            as="select"
            name="className"
            value={formData.className}
            onChange={handleChange}
            isInvalid={!!errors.className}>
            <option value="">Select class</option>
            <option>PRE KG</option>
            <option>LKG</option>
            <option>UKG</option>
            <option>Class 1</option>
            <option>Class 2</option>
            <option>Class 3</option>
            <option>Class 4</option>
            <option>Class 5</option>
            <option>Class 6</option>
            <option>Class 7</option>
            <option>Class 8</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.className}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="howDidYouHear" className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            name="howDidYouHear"
            placeholder="How did you hear about us?"
            value={formData.howDidYouHear}
            onChange={handleChange}
          />
        </Form.Group>

        <Button
          disabled={loading}
          variant="primary"
          className="w-100 fw-600 custom-btn"
          style={{ background: "white", color: "#5959C5" }}
          type="submit">
          SUBMIT ENQUIRY
        </Button>
      </Form>
    </div>
  );
}

export default FormComponent;
