import React,{useEffect} from 'react'
import {useLocation } from 'react-router-dom';
import Banner from '../Components/HighSchoolComponents/Banner/Banner'
import School from '../Components/HighSchoolComponents/School/School'
import Testimonials from '../Components/HighSchoolComponents/TestimonialsSchool/TestimonialsSchool'
import Programme from '../Components/HighSchoolComponents/Programme/Programme'
import Choice from '../Components/HighSchoolComponents/Choice/Choice'
import Curriculum from '../Components/HighSchoolComponents/Curriculum/Curriculum'
import Faculties from '../Components/HighSchoolComponents/Faculties/Faculties'
import AdminssionPre from '../Components/HighSchoolComponents/AdmissionPre/AdmissionPre'
import Information from '../Components/HighSchoolComponents/Information/Information'
// import SuccessStory from '../Components/HighSchoolComponents/SuccessStory/SuccessStory'
import Gallery from '../Components/HighSchoolComponents/Gallery/Gallery'
import Transportation from '../Components/HighSchoolComponents/Transportation/Transportation'
import Apply from '../Components/HighSchoolComponents/Apply/Apply'
import OtherSections from '../Components/HighSchoolComponents/OtherSections/OtherSections'
import ReactGA from "react-ga4";

ReactGA.initialize("G-KE1087VQ84");

ReactGA.send({ hitType: "pageview", 
                page: window.location.pathname, });
const PrePrimary = () => {
  const ScrollToTop = () => {
    const { pathname, state } = useLocation();
  
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (state && state.targetSection) {
          const targetSection = document.querySelector(state.targetSection);
          if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100); // Adding a slight delay to ensure scroll happens after the render
    }, [pathname, state]);
  
    return null;
  };
  return (
    <div>
           <ScrollToTop/>
      <Banner/>
      <School/>
      <Programme/>
      <Choice/>
      <Curriculum/>
      <Faculties/>
      <AdminssionPre/>
      <Information/>
      {/* <SuccessStory/> */}
      <Testimonials/>
      <Gallery/>
      <Transportation/>
      <Apply/>
      <OtherSections/>
      
    </div>
  )
}

export default PrePrimary
