import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Eventsbanner from '../Components/CoCurricularComponents/Eventsbanner/Eventsbanner'
import Ceremony from '../Components/CoCurricularComponents/Ceremony/Ceremony'
import EventsAdmission from '../Components/CoCurricularComponents/EventsAdmission/EventsAdmission'
import EventsInformation from '../Components/CoCurricularComponents/EventsInformation/EventsInformation'
import EventsGallery from '../Components/CoCurricularComponents/EventsGallery/EventsGallery'
import EventsOtherSection from '../Components/CoCurricularComponents/EventsOtherSection/EventsOtherSection'
import ReactGA from "react-ga4";

ReactGA.initialize("G-KE1087VQ84");

ReactGA.send({ hitType: "pageview", 
                page: window.location.pathname, });

const Events = () => {
  const ScrollToTop = () => {
  const { pathname, state } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      if (state && state.targetSection) {
        const targetSection = document.querySelector(state.targetSection);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 100); // Adding a slight delay to ensure scroll happens after the render
  }, [pathname, state]);

  return null;
};
  return (
    <div style={{background:"#FFF"}}>
      <ScrollToTop/>
        <Eventsbanner/> 
        <Ceremony/>  
        <EventsGallery/>
        <EventsAdmission/> 
        <EventsInformation/> 
        <EventsOtherSection/>        
    </div>
  )
}

export default Events